import React from "react"

export default function UpdateNews(){
    return(<>
        <div>
            <h2>Release News</h2><hr/>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 2.1.0.6</h3>
                </div>
                <div className="card-body">
                    Release März 2025<br/>
                    <ul>
                        <li>Testversion für Updater</li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.24.3</h3>
                </div>
                <div className="card-body">
                    Release März 2024<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/milestones/18">Milestone</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.24.2</h3>
                </div>
                <div className="card-body">
                    Release Februar 2024<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/milestones/17">Milestone</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.24.1</h3>
                </div>
                <div className="card-body">
                    Design Update - Dashboard<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/186">Ticket #186</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.23.4</h3>
                </div>
                <div className="card-body">
                    Untersuchungen Historie<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/milestones/12">Milestone</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.9.0</h3>
                </div>
                <div className="card-body">
                    BugFixing und kleine Anforderungen<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/milestones/14">Milestone</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.8.9</h3>
                </div>
                <div className="card-body">
                    Automatischer E-Mail Versand<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/milestones/10">Milestone</a></li>
                    </ul>
                    BugFixes<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/142">Aufruf der Filter-Funktion</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.7.3.1</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>
                            NU Datums aktualisiert sich beim Edit <a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/110">#110</a><br/>
                            jetzt nicht mehr Automatisch. Nur noch bei "Neu" Anlegen.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.7.5</h3>
                </div>
                <div className="card-body">
                    Major BugFixes<br/>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/119">#119</a></li>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/118">#118</a></li>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/114">#114</a></li>
                        <li><a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/110">#110</a></li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.7.3.1</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>
                            NU Datums aktualisiert sich beim Edit <a rel="noreferrer" target="_blank" href="https://gitlab.cloud.sobek-innovations.de/alexandra.sobek/vorsorgekartei/-/issues/110">#110</a><br/>
                            jetzt nicht mehr Automatisch. Nur noch bei "Neu" Anlegen.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.7.3</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>
                            Filter Sprung <a rel="noreferrer" target="_blank" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/106">#106</a>
                        </li>
                        <li>
                            Fehler beim Sprung und gesetzten Filter von der Mitarbeiter- zur Tätigkeitstabelle.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.7</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>
                            Mitarbeiter Übersicht in der Vorsorgekartei Issue <a rel="noreferrer" target="_blank" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/96">#96</a>
                        </li>
                        <li>
                            Kuchendiagramm Dashboard Widget Issue <a rel="noreferrer" target="_blank" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/94">#94</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.6.3</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>
                            Fehler beim Laden der Abhängigen Tätigkeiten oder auch der Untersuchungen.     
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-center">
                    <h3>Update 1.6.2</h3>
                </div>
                <div className="card-body">
                    <ul>
                        <li>Pagination angepasst <a rel="noreferrer" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/92" target="_blank">#92</a></li>
                        <li>Zeilen pro Seite für Pagination</li>
                        <li>Bugfixes Issue <a rel="noreferrer" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/91" target="_blank">#91</a></li>
                        <li>Issue <a rel="noreferrer" href="https://git.cloud1.sobek-innovations.de/alexandra.sobek/vorsorgekartei/issues/93" target="_blank">#93</a> - Untersuchung/Beurteilung bearbeiten</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
    )
}