import logger from "../logging/logger";

export function url_creation(resName, resourceIdOrQuery = '', maybeQuery = ''){
    let resourceId = '';
    let query = '';
    let headers = {
        method: "GET",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }
    //Query könnte auch ein Objekt sein, daher erst in String wandeln
    if(typeof resourceIdOrQuery === 'object'){
        logger.log("Object Query:", resourceIdOrQuery)
        resourceIdOrQuery = queryObjectToString(resourceIdOrQuery);
    }

    // Determine which parameter is which
    if (typeof resourceIdOrQuery === 'string' && resourceIdOrQuery.startsWith('?')) {
        query = resourceIdOrQuery;
    } else {
        resourceId = resourceIdOrQuery !== '' ? '/'+resourceIdOrQuery : '';
        if (typeof maybeQuery === 'object' && maybeQuery !== null) { 
            logger.log("Maybe Object Query:", maybeQuery);
            query = queryObjectToString(maybeQuery);
        } else {
            query = maybeQuery || '';  // Falls `maybeQuery` leer ist, ersetze es durch ''
        }
    }

    return {url: global.api_url + resName + resourceId + query, resourceId, query, headers}
}

/**
 * 
 * @param {Resouce Name} resName 
 * @param {id for single, can be empty} resourceId 
 * @param {query Params} query 
 */
export async function fetchApi(resName, resourceIdOrQuery = '', maybeQuery = '', retries = 3) {
    const { url, resourceId, query, headers } = url_creation(resName, resourceIdOrQuery, maybeQuery);

    for (let attempt = 0; attempt <= retries; attempt++) {
        try {
            const res = await fetch(url, headers);
            if (!res.ok) {
                if (res.status === 429) { // 429 = Too Many Requests
                    let retryAfter = parseInt(res.headers.get("Retry-After") || "1", 10) * 1000;
                    console.warn(`Rate Limit erreicht, warte ${retryAfter}ms...`);
                    await new Promise(resolve => setTimeout(resolve, retryAfter));
                    continue; // Wiederhole die Anfrage
                }
                const errorBody = await res.json();
                throw new Error(`Fehler: ${res.status}, ${JSON.stringify(errorBody)}`);
            }
            return await res.json();
        } catch (err) {
            console.error(`Fehler bei Versuch ${attempt + 1}:`, err);
            if (attempt === retries) throw err; // Letzter Versuch, Fehler weiterwerfen
        }
    }
}


/**
 * 
 * @param {*} resName 
 * @param {*} resourceId 
 * @param {*} data 
 * @returns Promise(resolve(response.json), reject(err))
 */
export async function putApi(resName, resourceId = '', data){
    let headers = {
        method: "PUT",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);

    // logger.log('ketest', headers.body)

    if(resourceId !== '') resourceId = '/'+ resourceId;
    
    let resp = await fetch(global.api_url + resName + resourceId, headers)
    let res = await resp.json()
    if(!resp.ok){
        throw new Error(res.error)
    }
    return res
}

export async function saveApi(resName, data){
    let headers = {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);
    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName, headers)
            .then(res => res.json())
            .then(response => {
                return resolve(response)
            })
            .catch(err => {
                return reject(err);
            })
    })
}


export async function deleteApi(resName, resourceId){
    let headers = {
        method: "DELETE",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }

    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName +"/" + resourceId, headers)
            .then(res => res.json())
            .then(response => {
                // logger.log('delete ok')
                return resolve(response)
            })
            .catch(err => {
                logger.log('err', err)
                return reject(err);
            })
    }) 
}



// Interne Helper Functions
function queryObjectToString(query) {
    if (!query || typeof query !== 'object') {
        logger.error("queryObjectToString: Ungültiges Query-Objekt, setze leeres Objekt.");
        return ''; // Falls `query` nicht definiert ist, einfach leeren String zurückgeben
    }
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(query)) {
        if (value !== undefined) {
            if (['sortOrder', 'orderBy', 'limit', 'currentPage', 'format'].includes(key)) {
                params.append(key, value);
            } else {
                params.append('filterby_' + key, value);
            }
        }
    }
    return params.toString() !== '' ? '?' + params.toString() : '';
}
