
import moment from 'moment';
import React from 'react';
import logger from '../../logging/logger';

class HistoryList extends React.Component {

    state ={
        history: [],
    }

    async validate(props){
        this.setState({ 
            history: props.liste,
        })
    }

    componentDidMount(){
        this.validate(this.props)
    }

    componentDidUpdate(newprops){
        if(this.props !== newprops){
            this.validate(newprops)
        }
    }

    async setDateienDownloadLinks(dateien){
        let tmp = [];
        // for(let key in dateien){
        //     let datei = dateien[key];
        //     // logger.log(typeof datei, datei)
        //     tmp.push(
        //         <div key={datei.id} className="col-auto text-center pt-3 mx-3  datei">
        //             <a href={global.api_url+"datei/"+datei.id+"?authorization="+global.auth.authToken.token+"&binary=true"} rel="noreferrer" target="_blank">
        //                 <i className="far fa-file-alt"/>
        //                 <p className="datei-name">{ datei.name }</p>
        //                 <p style={ {fontSize: ".8em"} }>({ moment(datei.updatedAt).format('DD.MM.YYYY - hh:mm:ss') })</p>
        //             </a>
        //             <i className="fas fa-times" data-datei={JSON.stringify(datei)} onClick={this.toggleConfirmDialog}></i>
        //         </div>
        //     );
        // }

        logger.log("Hist", dateien)
        return tmp;
    }

    render() {
        logger.log("Hist", this.state.history)
        return (
            <div>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>BG Grundsatz</th>
                            <th>Art</th>
                            <th>gemacht</th>
                            <th>nächster Termin</th>
                            {/* <th>gespeichert</th> */}
                            <th>Beurteilung</th>
                            <th>Kommentar</th>
                            <th>Dateien</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.history.map((untersuchung) => {
                        const dateiLinks = untersuchung.Datei.map((datei) => {
                            logger.log(datei);
                            return (
                                <div key={datei.id}>
                                    <a href={global.api_url + "datei/" + datei.id + "?authorization=" + global.auth.authToken.token + "&binary=true"} rel="noreferrer" target="_blank">
                                        <p className="datei-name" alt={moment(datei.updatedAt).format('DD.MM.YYYY - hh:mm:ss')}>{datei.name}</p>
                                    </a>
                                </div>
                            );
                        });

                        return (
                            <tr key={untersuchung.id}>
                                <td>{untersuchung.firmenkartei.bg_grundsatz}</td>
                                <td>{untersuchung.art}</td>
                                <td>{moment(untersuchung.gemacht_am).format('DD.MM.YY')}</td>
                                <td>{moment(untersuchung.naechster_termin).format('MM/YY')}</td>
                                <td>{untersuchung.beurteilung}</td>
                                <td>{untersuchung.kommentar}</td>
                                <td>{dateiLinks}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default HistoryList;