import React, { Component } from "react";
import ReactForm from "@rjsf/core";
import validator from "@rjsf/validator-ajv8"; 
import logger from "../../logging/logger";
import { putApi } from "../../utils/DatabaseHelper";
import { BENUTZER } from "../../utils/TableConstanten";
import { toast } from "react-toastify";

const uiSchema = {
    "readonly": { "ui:readonly": true },
    "pass": { "ui:widget": "password" },
    "new_pass": { "ui:widget": "password" },
    "new_pass_wdh": { "ui:widget": "password" },
    "secure": { "ui:widget": "checkbox" },
};

class Account extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            profile: { pass: "", new_pass: "", new_pass_wdh: "" },
        };
        this.state = { ...this.initialState };

        this.inputChange = this.inputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validatePasswords = this.validatePasswords.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    inputChange(event) {
        clearTimeout(this.editTimer);
        this.editTimer = setTimeout(() => {
            this.setState({ profile: event.formData });
        }, 500);
    }

    async onSubmit() {
        logger.log("Passwortänderung gesendet", this.state.profile);
        try {
            let res = await putApi(BENUTZER, global.auth.user.id, this.state.profile);
            logger.log(res);
            toast.success(res.message);
            this.resetForm();
            
        } catch (error) {
            logger.error(error);       
            toast.error(error.message || "Unbekannter Fehler beim Speichern!"); 
        }
    }

    /**
     * Überprüft, ob das Passwort alle Anforderungen erfüllt.
     */
    validatePasswordStrength(password) {
        let errors = [];

        if (password.length < 9) {
            errors.push("Mindestens 9 Zeichen lang");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Mindestens ein Großbuchstabe");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Mindestens ein Kleinbuchstabe");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("Mindestens eine Zahl");
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push("Mindestens ein Sonderzeichen (!@#$%^&* etc.)");
        }

        return errors;
    }

    validatePasswords(formData, errors) {
        let passwordErrors = this.validatePasswordStrength(formData.new_pass);

        if (passwordErrors.length > 0) {
            errors.new_pass.addError("Passwort muss folgende Kriterien erfüllen:");
            passwordErrors.forEach(err => errors.new_pass.addError(`- ${err}`));
        }

        if (formData.new_pass && formData.new_pass_wdh && formData.new_pass !== formData.new_pass_wdh) {
            errors.new_pass_wdh.addError("Die Passwörter müssen übereinstimmen!");
        }

        return errors;
    }

    resetForm() {
        this.setState({ ...this.initialState });
        this.props.setModalShow(false);
    }

    render() {
        const { modalShow } = this.props;

        return (
            <>
                <div className={"modal fade " + (modalShow ? "show" : "")}
                     id="modal-default"
                     aria-modal="true"
                     role="dialog"
                     aria-hidden="true"
                     style={modalShow ? { paddingRight: 15, display: "block" } : {}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content" style={{height: "auto", flex: "none"}}>
                            <div className="modal-header">
                                <h4 className="modal-title">Passwort ändern</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.resetForm}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ReactForm
                                    id="MailTest"
                                    autofill="off"
                                    validator={validator}
                                    liveValidate
                                    schema={{
                                        type: "object",
                                        properties: {
                                            pass: { type: "string", title: "Altes Passwort" },
                                            new_pass: { type: "string", title: "Neues Passwort" },
                                            new_pass_wdh: { type: "string", title: "Neues Passwort wiederholen" }
                                        },
                                        required: ["pass", "new_pass", "new_pass_wdh"] // Pflichtfelder setzen
                                    }}
                                    formData={this.state.profile}
                                    uiSchema={uiSchema}
                                    onChange={this.inputChange}
                                    onSubmit={this.onSubmit}
                                    customValidate={this.validatePasswords}
                                    showErrorList="none"
                                >
                                    <button type="button" className="btn btn-secondary" onClick={this.resetForm}>
                                        Abbrechen
                                    </button>
                                    <button type="submit" className="btn btn-primary" style={{float: "right"}}>
                                        Speichern
                                    </button>
                                </ReactForm>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Account;
