import { React, Component } from "react";
import PropTypes from "prop-types";
import { deleteApi, putApi, saveApi } from "../../utils/DatabaseHelper";
import Select from 'react-select';
import ReactForm from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import mailingTriggers from "./trigger/index";
import cronstrue from 'cronstrue/i18n';
import { toast } from "react-toastify";
import logger from "../../logging/logger";

class MailForm extends Component {
    constructor(props) {
        super(props);
        this.default_email = {
            id: 0,
            trigger: '',
            bezeichnung: 'Neu*',
            content: {},
            data: []
        };
        this.state = { 
            email: {
                id: 0,
                trigger: '',
                transformErrors: '',
                bezeichnung: 'Neu*',
                content: {},
                data: []
            },
            untersuchungen: this.props.untersuchungen,
            triggerSelectionValue: [],
            triggerSelectionOptions: [{ value: 'db-name', label: "nice Name" }],
            cronString: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.onInputChangeText = this.onInputChangeText.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.handleTriggerSelectChange = this.handleTriggerSelectChange.bind(this);
    }

    validate(props){
        //get trigger Options, then set trigger if email#
        this.setState(()=> {
            let tmp = [];
            for(var trigger in mailingTriggers){
                // logger.log(trigger)
                tmp.push({value: trigger, label: mailingTriggers[trigger].label})
            }
            return {triggerSelectionOptions: tmp}
        }, () => { //set email props
            // logger.log("props", props.email, props.untersuchungen)
            if(typeof props.email.content !== 'undefined' && props.email.content !== ''){
                //CRON Timing Update
                let cronText = "" 
                try{ cronText = cronstrue.toString(JSON.parse(props.email.content).cron, {locale: "de"}) }
                catch(err){ cronText = err; }

                this.setState({
                        email: {
                            ...props.email, 
                            content: JSON.parse(props.email.content), 
                            data: JSON.parse(props.email.data),
                            uischema: {
                                ...props.email.uischema,
                                cron: {
                                    "ui:help": cronText
                                }
                            }
                        }
                    }, 
                    () => {
                        // logger.log('val', this.state)
                        if(typeof this.state.email.trigger !== 'undefined' && this.state.email.trigger !== ''){
                            this.setState({
                                triggerSelectionValue: { 
                                    value: mailingTriggers[this.state.email.trigger], 
                                    label: mailingTriggers[this.state.email.trigger].label
                                }
                            });
                        }
                    });
            }

        });

    }
        
    componentDidMount() {
        this.validate(this.props);
    }
    componentDidUpdate(newProps) {
        // logger.log('DidUpdate', newProps !== this.props, newProps, this.props)
        if(newProps !== this.props){
            // logger.log(newProps.email.updatedAt > this.props.email.updatedAt, newProps.email.updatedAt,this.props.email.updatedAt  )
            if(newProps.email.updatedAt > this.props.email.updatedAt){
                this.validate(newProps); 
            }else { 
                this.validate(this.props); 
            }
        }
    }

    async handleSubmit(){
        logger.log('save this:', this.state.email)
        // logger.log(typeof this.state.email.id !== 'undefined')

        let data = this.state.email
        data.content = JSON.stringify(data.content);
        data.data = JSON.stringify(data.data)

        if(typeof this.state.email.id !== 'undefined' && this.state.email.id !== 0){ //put
            // logger.log('put', data)
            await putApi("emails", this.state.email.id, data)
                .then(resp => {
                    // logger.log('ketest', resp)
                    toast.success(resp.message, {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        });
                })
                .catch(err => {
                    logger.log('err', err)
                    toast.success(err.message, {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        });
                })
        }else{ //post
            // logger.log('ppost', data)
            await saveApi("emails", data )
                .then(resp => {
                    // logger.log('ketest', resp)
                    toast.success(resp.message, {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        });
                })
                .catch(err => {
                    logger.log('err', err)
                    toast.success(err.message, {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        });
                })
        }
        global.emitter.emit("REFRESH_EMAIL");

    }

    async onInputchange(event){
        logger.log("InputChange", event)
        //timeout
        // clearTimeout(this.editTimer);
        // this.editTimer = setTimeout(() => {
            let fS = mailingTriggers[this.state.email.trigger].form_schema;
            if(event.formData){
                //SendMail to abfrage
                if(event.formData.to.email) {
                    event.formData.to.mitarbeiter = "E-Mail geht an den betreffenden Mitarbeiter"
                    delete event.formData.to.mitarbeiter;
                }else if (event.formData.to.mitarbeiter) {
                    delete event.formData.to.email;
                }

                //newEmploye when 
                if(event.formData.when.zahl !== undefined || event.formData.when.auswahl !== undefined) { 
                    delete event.formData.when.sofort;
                }else if(event.formData.when.zahl === undefined && event.formData.when.auswahl === undefined){
                    event.formData.when.sofort = "E-Mail wird direkt nach dem Anlegen verschickt."
                }

                //Check To FILTER einstellung
                if(event.formData.to["Begrenzung"]){
                    logger.log("toFilter", event.formData.to["Begrenzung"]['Filter']);
                    if(event.formData.to["Begrenzung"]['Filter'] && event.formData.to["Begrenzung"]["Filter"] === "Nach Untersuchungen aus dem Tätigkeitsbereich"){
                        let untersuchungen = await this.props.filterUntersuchungen(event.formData.to["Begrenzung"].taetigkeit) 
                        fS.definitions.untersuchungen.items.anyOf = untersuchungen.length > 0 ? untersuchungen : [{const: "0", title: 'Keine Untersuchungen in diesem Bereich'}];
                    }else {
                        fS.definitions.untersuchungen.items.anyOf = this.props.untersuchungen;
                    }
                }

                //CRON Timing Update
                let cronText = "" 
                try{
                    cronText = cronstrue.toString(event.formData.cron, {locale: "de"})
                }catch(err){
                    cronText = err;
                }
                
                this.setState(prevState => ({
                    email: {
                      ...prevState.email,
                      content: event.formData,
                      uischema: {
                        ...prevState.email.uischema,
                        cron: {
                            "ui:help": cronText
                        }
                      }
                    }
                  }));
                logger.log('inpChan', event.formData , mailingTriggers[this.state.email.trigger].form_schema)
            }
        // }, 500);
    }
    onInputChangeText(event){
        logger.log("inputChangeText", event.target.name, event.target.value)
        this.setState({email: {...this.state.email, [event.target.name]: event.target.value}})
    }

    deleteTemplate(){
        // logger.log('Delete')
        deleteApi("emails", this.state.email.id)
            .then(resp => {
                // logger.log(resp)
                toast.success(resp.message)
            })
            .catch(err => {
                logger.log('err Delete', err)
                toast.error(err.message)
            })
            .finally(() => {
                global.emitter.emit("REFRESH_EMAIL");
            })

    }

    async handleTriggerSelectChange(e){
        logger.log("select change", this.props);
        this.setState({triggerSelectionValue: e}, () => {
            let fS = mailingTriggers[e.value].form_schema
            if (e.value === 'comingUpExamination') {
                fS.definitions.taetigkeit.items.anyOf = this.props.taetigkeiten || [];
                fS.definitions.untersuchungen.items.anyOf = this.props.untersuchungen || [];
            }
            this.setState({ email: { ...this.state.email, 
                content: {},
                trigger: e.value, 
                transformErrors: mailingTriggers[e.value].transformErrors,
                formSchema: fS,
                uischema: mailingTriggers[e.value].uiSchema,
            }})
        })
    }

    getFunctionContext(){
        let functionsContext = mailingTriggers[this.state.email.trigger] !== 'undefined' ? ( mailingTriggers[this.state.email.trigger].context_functions ? mailingTriggers[this.state.email.trigger].context_functions: []) : [];
        if(functionsContext.length > 0){
            return <>
                <span className="desc">Funktionen:</span><br/>
                { functionsContext.map((con, idx) => {
                    return <span key={idx} className="desc">{con}, </span>
                }) }
                <hr/>
            </>
        }
        return <></>
    }
    
    getMailHelperContext(){
        let mailContext = mailingTriggers[this.state.email.trigger] !== 'undefined' ? mailingTriggers[this.state.email.trigger].mail_context : [];
        if(mailContext.length > 0){
            return <> 
                <span className="desc">Folgende Variablen können bei diesem Auslöser benutzt werden:</span><br/>
                { mailContext.map((con, idx) => {
                    return <span className="desc" key={idx}>{con}, </span>
                })}
                <hr/>
            </>
        }
        return <></>
    }

    getTriggerFormular(){
        let retHtml = <p className="text-center">Bitte Auslöser wählen.</p>;
        if(this.state.email.trigger !== ''){
            let contextHelp = 
                    <div className="mail-context">
                        { this.getMailHelperContext() }
                        { this.getFunctionContext() }
                    </div>

            retHtml = (<>
            <pre className="d-none">{JSON.stringify(this.state.email.content, null, 2)}</pre>
                <ReactForm
                    schema={this.state.email.formSchema}
                    transformErrors={this.state.email.transformErrors}
                    showErrorList={false}
                    formData={this.state.email.content}
                    validator={validator}
                    uiSchema={this.state.email.uischema}
                    onSubmit={this.handleSubmit}
                    onChange={e => this.onInputchange(e)}
                >
                    {contextHelp}
                    <hr/>
                    <button type="submit" className="btn btn-primary">Speichern</button>
                </ReactForm>
            </>)
        }

        return (
            <div className="options">
                { retHtml }
            </div>
        );
    }

    render() {
        let formular = this.getTriggerFormular();
        return (
            <div className="card">
                <div className="card-header d-flex">
                    <h5 className="mb-0 col pl-0">
                        <button className="btn btn-link pl-0" type="button" data-toggle="collapse" data-target={"#collapse-"+this.state.email.id} aria-expanded="true" aria-controls={"collapse-"+this.state.email.id}>
                        {this.state.email.bezeichnung}  - Auslöser: `&quot;`{this.state.email.trigger !== '' ? mailingTriggers[this.state.email.trigger].label : 'nicht zugewiesen'}`&quot;`;
                        </button>
                    </h5>
                    <button type="button" 
                        disabled={typeof this.state.email.id === 'undefined'} 
                        className="btn btn-sm btn-default" onClick={this.deleteTemplate}><i className="fas fa-trash-alt"></i></button>
                </div>
                <div id={"collapse-"+this.state.email.id} className="collapse" aria-labelledby="heading" data-parent="#accordionEmails">
                    <div className="card-body">
                        <div className="form-group email" key={this.state.email.id}>
                            <div id={this.state.email.id}>
                                <div className="form-group">
                                    <label htmlFor="bezeichnung">Bezeichnung</label>
                                    <input type="text" id="bezeichnung" name="bezeichnung" className="form-control" value={this.state.email.bezeichnung} 
                                        onChange={this.onInputChangeText}  />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="trigger">Versand Auslöser:</label>
                                    <Select 
                                        placeholder = "Auswählen"
                                        options={ this.state.triggerSelectionOptions} 
                                        value= { this.state.triggerSelectionValue }
                                        name="trigger"
                                        className="basic-multi-select"
                                        onChange={ this.handleTriggerSelectChange }
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                            ...theme.colors,
                                            primary25: '#ffeebe',
                                            primary: '#ffd370',
                                            },
                                        })}
                                    />
                                    <span className="desc">{typeof mailingTriggers[this.state.email.trigger] !== 'undefined' ? mailingTriggers[this.state.email.trigger].desc : ''}</span>
                                </div>
                                { formular }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
MailForm.propTypes = {
    email: PropTypes.object,
    taetigkeiten: PropTypes.array,
    untersuchungen: PropTypes.array
}

export default MailForm;