const DEBUG_MODE = process.env.NODE_ENV === "development" || window._env_?.REACT_APP_LOGGING === "true";

const getCallerInfo = () => {
    try {
        throw new Error(); // Stack-Trace erzeugen
    } catch (e) {
        const stackLines = e.stack.split("\n").map(line => line.trim());

        // Suche nach der ersten relevanten Zeile, die nicht `logger.js` ist
        const relevantLines = stackLines.filter(line =>
            !line.includes("logger.js") && !line.includes("Error") && line.includes("at ")
        );

        if (relevantLines.length >= 3) {
            // Extrahiere nur den Funktionsnamen ohne URL
            return relevantLines[2].split(" (")[0]; // Entfernt alles nach " ("
        }

        return "Unknown location";
    }
};

// 🔥 Haupt-Logger mit sauberem Stack-Trace
const logger = {
    log: (...args) => {
        if (DEBUG_MODE) {
            const callerInfo = getCallerInfo();
            console.log(`[DEBUG] (${callerInfo}):`, ...args);
        }
    },
    warn: (...args) => {
        if (DEBUG_MODE) {
            const callerInfo = getCallerInfo();
            console.warn(`[WARNING] (${callerInfo}):`, ...args);
        }
    },
    error: (...args) => {
        const callerInfo = getCallerInfo();
        console.error(`[ERROR] (${callerInfo}):`, ...args);
    }
};

export default logger;
